import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Layout from '../components/Layout'
import './index.scss'
import './howto.scss'

export default () => (
  <Layout>
    <div className="background container-fluid">
      <AniLink cover bg="#ec277e" to="/" className="back-button">
        Back
      </AniLink>

      <div className="container-fix">
        <div className="row">
          <div className="col">
            <h1>NotNow on Twitter</h1>
            <p>
              NotNow, the reminder service also works on Twitter, and it's even
              easier! Just ask{' '}
              <a href="https://twitter.com/notNowCo" target="_blank">
                @NotNowCo
              </a>{' '}
              to remind you of a specific tweet via a mention.
            </p>

            <h2>A simple mention in answer to a tweet</h2>
            <p>
              To remind you of a certain tweet, just answer this tweet with a
              mention to{' '}
              <a href="https://twitter.com/notNowCo" target="_blank">
                @NotNowCo
              </a>{' '}
              and a reminder time.
            </p>

            <p>Use the following commands:</p>
            <h3>In some time</h3>
            <ul>
              <li>in X minutes</li>
              <li>in X hours</li>
              <li>in X days</li>
              <li>in X weeks</li>
              <li>in X months</li>
            </ul>
            <p>
              For example, I can receive a reminder for a tweet in 3 hours if I
              answer to it:
            </p>
            <p>
              "Hey{' '}
              <a href="https://twitter.com/notNowCo" target="_blank">
                @NotNowCo
              </a>
              , can you remind me of this tweet in 3 hours ?"
            </p>
            <p>
              Be creative, only a mention to{' '}
              <a href="https://twitter.com/notNowCo" target="_blank">
                @NotNowCo
              </a>{' '}
              and "in XXX YYY" are mandatory in the tweet. 😉
            </p>

            {/*
            <h3>On a specific day</h3>
            <p>
              To receive the email back on a specific day, use one of the
              following address:
            </p>
            <ul>
              <li>Monday: onmonday@notnow.co</li>
              <li>Tuesday: ontuesday@notnow.co</li>
              <li>Wednesday: onwednesday@notnow.co</li>
              <li>Thursday: onthursday@notnow.co</li>
              <li>Friday: onfriday@notnow.co</li>
              <li>Saturday: onsaturday@notnow.co</li>
              <li>Sunday: onsunday@notnow.co</li>
            </ul>

            <h3>The next day, week, month</h3>
            <p>
              To receive the email back at the begining of the next day, week,
              or month, use one of the following:
            </p>
            <ul>
              <li>nextday@notnow.co</li>
              <li>nextweek@notnow.co</li>
              <li>nextmonth@notnow.co</li>
            </ul>

            <h2>Use cases</h2>
            <p>
              NotNow can be used in many situations, here are some use cases
              reported by our users:
            </p>
            <ul>
              <li>
                You sent a message to a prospect and want to follow in 5 days:
                Just CC in5days@notnow.co
              </li>
              <li>
                You have a bill to pay next month: Simply forward your message
                to nextmonth@notnow.co
              </li>
              <li>
                You receive an email on your phone and want to read it on your
                computer when arriving at the office: Simply forward your
                message to in2hours@notnow.co
              </li>
              <li>
                You want to cancel your free Netflix subscription: Forward the
                welcome email to in29days@notnow.co
              </li>
            </ul>

            <p>
              You have another example of usage ? Send them to
              nicolas@notnow.co, I'll add them here.
            </p>*/}

            <h2>What happens behind ?</h2>
            <p>
              <a href="https://twitter.com/notNowCo" target="_blank">
                @NotNowCo
              </a>{' '}
              will like your tweet if he understood your request. If your tweet
              is liked, it means it's in the reminder's list. The bot will
              unlike your tweet when sending the reminder. To cancel a reminder,
              just remove your mentionning tweet to{' '}
              <a href="https://twitter.com/notNowCo" target="_blank">
                @NotNowCo
              </a>
              .
            </p>
            <p>
              We don't store any list of tweets, usage or anything else, the bot
              simply checks who mention it and which tweets he liked, then he
              answers it.
            </p>

            <hr />
            <footer>
              NotNow is a free tool developed by{' '}
              <a href="https://gp2mv3.com" target="_blank">
                Nicolas Cognaux
              </a>
              . Don't hesitate to contact me with your feedback!
            </footer>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
